
<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.PARTNER") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.partners"
                v-model="formModel.status"
                :items="statuses.partners.partner"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
            <!--
            <v-row>
                <v-col cols="12" sm="6" md="6">{{ formModel }}</v-col>
            </v-row> 
            -->

          <v-row>   
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="formModel.company_name"
                :rules="nameRules"
                :label="$t('FORMS.company_name')"
                :id="dynamicID"
                :error-messages="
                  messages['company_name']
                "
                @keyup="
                  messages['company_name'] = ''
                "
              ></v-text-field>
            </v-col> 
            <!-- <v-col cols="12" sm="2" md="2">
               <v-text-field
                type="number"    
                clearable="true"
                step="any"
                min="0"
                v-model="formModel.position"
                :label="$t('FORMS.position')"
                :id="dynamicID"
                :error-messages="
                  messages['position']
                "
                @keyup="
                  messages['position'] = ''
                "
              ></v-text-field>   
            </v-col> --> 
          </v-row>         
          <v-row>
            <v-col cols="3" xs="6">
              <v-select
                  v-model="formModel.country_id"
                  :items="countryCollection"
                  label="Select Country"
                  item-text="name"
                  item-value="id"
                  :rules="countryRules"
                />
            </v-col>
            <v-col cols="2" xs="6">
              <v-text-field
                v-model="formModel.zip"
                :rules="nameRules"
                :label="$t('FORMS.zip')"
                :id="dynamicID"
                :error-messages="
                  messages['zip']
                "
                @keyup="
                  messages['zip'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="3" xs="6">
              <v-text-field
                v-model="formModel.city"
                :rules="nameRules"
                :label="$t('FORMS.city')"
                :id="dynamicID"
                :error-messages="
                  messages['city']
                "
                @keyup="
                  messages['city'] = ''
                "
              ></v-text-field>
            </v-col>  
            <v-col cols="4" xs="6">
              <v-text-field
                v-model="formModel.address"
                :rules="nameRules"
                :label="$t('FORMS.address')"
                :id="dynamicID"
                :error-messages="
                  messages['address']
                "
                @keyup="
                  messages['address'] = ''
                "
              ></v-text-field>
            </v-col>  
          </v-row>
            
          <v-row>
              <v-col cols="4" xs="6">
                  <v-text-field
                    v-model="formModel.phone"
                    :label="$t('FORMS.phone')"
                    :id="dynamicID"
                    :error-messages="
                      messages['phone']
                    "
                    @keyup="
                      messages['phone'] = ''
                    "
                  ></v-text-field>
              </v-col>
              <v-col cols="4" xs="6">
                  <v-text-field
                    v-model="formModel.email"
                    :label="$t('FORMS.email')"
                    :id="dynamicID"
                    :error-messages="
                      messages['email']
                    "
                    @keyup="
                      messages['email'] = ''
                    "
                  ></v-text-field>
              </v-col>
              <v-col cols="4" xs="6">
                  <v-text-field
                    v-model="formModel.web"
                    :label="$t('FORMS.web')"
                    :id="dynamicID"
                    :error-messages="
                      messages['web']
                    "
                    @keyup="
                      messages['web'] = ''
                    "
                  ></v-text-field>
              </v-col>
          </v-row>  
            
          <v-row>
              <v-col cols="4" xs="6">
                  <v-text-field
                    v-model="formModel.latitude"
                    :label="$t('FORMS.latitude')"
                    :id="dynamicID"
                    :error-messages="
                      messages['latitude']
                    "
                    @keyup="
                      messages['latitude'] = ''
                    "
                  ></v-text-field>
              </v-col> 
              <v-col cols="4" xs="6">
                  <v-text-field
                    v-model="formModel.longitude"
                    :label="$t('FORMS.longitude')"
                    :id="dynamicID"
                    :error-messages="
                      messages['longitude']
                    "
                    @keyup="
                      messages['longitude'] = ''
                    "
                  ></v-text-field>
              </v-col> 
          </v-row>  
            
          <!--
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="desktop_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .desktop_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.desktop_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="mobile_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .mobile_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.mobile_image',
                  selectButtonTextNotTransalated: '(320*200px)',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12"> </v-col>
          </v-row>
          -->
          
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Partner";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
//import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  //"seoTitle",
  //"seoDescription",
  //"desktop_image",
  //"mobile_image",
  //"og_title",
  //"og_description",
  //"og_url",
  //"og_site_name",
  //"og_image",
];

export const initialFormData = () => ({
  id: null,
  status: 1,
  position: null, 
  company_name: null,
  country_id: "HU",
  zip: null,
  city: null,
  address: null,
  phone: null,
  email: null,
  web: null,
  latitude: null,
  longitude: null, 
  translations: {},
  // custom_fields: [],
});

export default {
  name: "PartnerForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  //components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,
      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      countryRules: [(v) => !!v || "Country is required"],
    };
  },
  computed: {
    ...mapGetters(["countryCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            //this.$helpers.getTranslated(this.formModel.translations).name
            this.formModel.company_name
        : this.$t("MENU.NEW") +
            " " +
            this.$t("MENU.PARTNER").toLowerCase();
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchCountry"]),
  
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      console.log(model);
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  mounted() {
    this.setTranslatedAttributes();
    this.fetchCountry();
  },
};
</script>
